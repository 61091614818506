import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { AuthenticationService } from './service/authentication.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  copyRightYear = new Date().getFullYear().toString();
  errorMessage = '';
  returnUrl = '';
  loginForm: FormGroup;
  subscriptions: Subscription[] = [];
  updateUser = {
    is_active: true,
  };
  previousUrl = '';

  setNavLayout: string;
  themeLayout: string;
  setDefaultNavbar: string;
  setToggleNavbar: string;
  setToggleStatus: boolean;
  setVerticalNavbarEffect: string;
  setDeviceType: string;
  setHeaderColorTheme: string;
  setLeftHeaderColorTheme: string;
  setNavbarColorTheme: string;
  setActiveNavColorTheme: string;
  setHeaderHeight: number;
  setFooterHeight: number;
  setCollapsedLeftHeader: boolean;
  setThemeStatus: any;

  constructor(
    private authenticationService: AuthenticationService,
    private _router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private layoutService: LayoutService
  ) {
    if (!sessionStorage.getItem('themeStatus')) {
      sessionStorage.setItem(
        'themeStatus',
        JSON.stringify(this.updateUser['is_active'])
      );
    }

    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]],
      secret_key: [''],
      user_key: [''],
    });

    this.subscriptions.push(
      this.authenticationService.ErrorMessage.subscribe((errorMessagee) => {
        this.errorMessage = errorMessagee;

        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
      })
    );
  }

  ngOnInit(): void {
    if (localStorage.getItem('userType') == 'ADM') {
      this.returnUrl == 'user-management';
    } else {
      this.returnUrl == 'batch-search';
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this._router.navigate([this.returnUrl]);

    this.layoutService.navLayoutCast.subscribe(
      (navlayout) => (this.setNavLayout = navlayout)
    );

    this.layoutService.dfNavbarCast.subscribe(
      (dfNavbar) => (this.setDefaultNavbar = dfNavbar)
    );
    this.layoutService.toggleNavbarCast.subscribe(
      (tNavbar) => (this.setToggleNavbar = tNavbar)
    );
    this.layoutService.tStatusCast.subscribe(
      (tStatus) => (this.setToggleStatus = tStatus)
    );
    this.layoutService.nvEffectCast.subscribe(
      (nvEffect) => (this.setVerticalNavbarEffect = nvEffect)
    );
    this.layoutService.headerThemeCast.subscribe(
      (headerTheme) => (this.setHeaderColorTheme = headerTheme)
    );
    this.layoutService.leftHeaderThemeCast.subscribe(
      (leftHeaderTheme) => (this.setLeftHeaderColorTheme = leftHeaderTheme)
    );
    this.layoutService.navbarThemeCast.subscribe(
      (navbarTheme) => (this.setNavbarColorTheme = navbarTheme)
    );
    this.layoutService.activeNavThemeCast.subscribe(
      (activeNavTheme) => (this.setActiveNavColorTheme = activeNavTheme)
    );

    this.setThemeStatus = sessionStorage.getItem('themeStatus');
    this.layoutService.themeLayoutCast.subscribe(
      (themeLayout) => (this.themeLayout = themeLayout)
    );
    this.layoutService.collapsedLeftHeaderCast.subscribe(
      (collapsedLeftHeader) =>
        (this.setCollapsedLeftHeader = collapsedLeftHeader)
    );
    this.layoutService.deviceTypeCast.subscribe(
      (appDeviceType) => (this.setDeviceType = appDeviceType)
    );

    this.setHeaderHeight = this.layoutService.headerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.layoutService.getVerticalNavbarOnWindowResize(event.target.innerWidth);
  }

  Login() {
    if (this.loginForm.valid) {
      this.spinner.show('loginLoading');
      setTimeout(() => {
        this.authenticationService.loginUser(this.loginForm.value).subscribe(
          (result) => {
            if (result.status == 'ok') {
              this.authenticationService
                .checkAuth(
                  this.loginForm.value.user_key.trim(),
                  this.loginForm.value.secret_key.trim(),
                  JSON.stringify(result.token)
                )
                .subscribe(
                  (data) => {
                    console.log('data--', data);
                    if (data && data.status == 'ok') {
                      sessionStorage.setItem(
                        'currentUserTrellis',
                        result.token
                      );
                      sessionStorage.setItem(
                        'secretKey',
                        this.loginForm.value.secret_key
                      );
                      sessionStorage.setItem(
                        'userKey',
                        this.loginForm.value.user_key
                      );

                      sessionStorage.setItem('userID', data.userID);
                      if (
                        data &&
                        data.user_type &&
                        data.user_type.toUpperCase() == 'ADM'
                      ) {
                        this._router.navigate(['pages/user-management']);
                      } else {
                        this._router.navigate(['pages/batch-search']);
                      }
                      localStorage.setItem('userType', data.user_type);
                      sessionStorage.setItem('loggedIn', JSON.stringify(true));
                      this.spinner.hide('loginLoading');
                    } else {
                      this.spinner.hide('loginLoading');
                      this._router.navigate(['login']);
                      return;
                    }
                  },
                  (error) => {
                    this.spinner.hide('loginLoading');
                    this._router.navigate(['login']);
                    return;
                  }
                );
            } else {
              this.spinner.hide('loginLoading');
              this._router.navigate(['login']);
              this.authenticationService.ErrorMessage.next(
                'Invalid user id or password. Try again!'
              );
              return;
            }
          },
          (error) => {
            this.spinner.hide('loginLoading');
            this._router.navigate(['login']);
            this.authenticationService.ErrorMessage.next(
              'Service Temporarily Unavailable, Please try again later!'
            );
            return;
          }
        );
      }, 0);
    }
  }

  changeType() {
    (document.getElementById('password') as any).type = 'password';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
